import { FullPageCard } from '@/app/components'
import HeardFromSurvey from '@/app/features/heard-from-survey'

const HeardFromSurveyPage = () => {
  return (
    <FullPageCard>
      <HeardFromSurvey />
    </FullPageCard>
  )
}

export default HeardFromSurveyPage
