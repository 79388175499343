import {
  BooleanInput,
  Question,
  Questionnaire,
  SelectInput,
} from '@/app/components'
import TextFormField from '@/components/forms/text-form-field'
import { useForm } from 'react-hook-form'
import {
  QuestionnaireButtonType,
  QuestionnairePageValue,
} from '@/app/components/Questionnaire/Questionnaire'
import data from './assets/data.json'
import { useNavigation } from '@/app/hooks'
import { useUrlSearchParam } from '@/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import { BreakpointContext } from '@/context'
import { useContext } from 'react'
import { track } from '@/logging/mixpanel'

type HeardFromSurveyQuestionnairePageKey =
  | 'heardFrom'
  | 'onlineSearch'
  | 'socialMedia'
  | 'fromElectric'
  | 'newsOrReview'
  | 'other'

type QuestionnairePageProps = {
  onChange?: (event?: unknown) => void
}

type QuestionPageConfig = {
  order: number
  key: HeardFromSurveyQuestionnairePageKey
}

const useViewModel = () => {
  const navigation = useNavigation()
  const redirectUrl = useUrlSearchParam('redirect')
  const { submitResponse } = useSurveyResponder('HeardFrom')

  const formatResponsesForSurvey = (responses: QuestionnairePageValue[]) => {
    return responses.reduce((acc, response) => {
      return {
        ...acc,
        // only get the last element in the value
        [response.key]: response.value[response.value.length - 1],
      }
    }, {} as Record<HeardFromSurveyQuestionnairePageKey, unknown>)
  }

  const logSurvey = (responses: QuestionnairePageValue[]) => {
    const selections = responses.map((res) => res.value[res.value.length - 1])
    track('Onboarding_HowDidYouHearAboutUs', {
      SourceSelected: selections,
    })
  }

  const onSubmit = async (responses: QuestionnairePageValue[]) => {
    const formattedResponses = formatResponsesForSurvey(responses)
    logSurvey(responses)
    await submitResponse(formattedResponses)
    navigation.push(redirectUrl ?? '/app?survey_complete=true')
  }

  return {
    onSubmit,
    setShowSkipModalId: () => {},
    showSkipModalId: null,
  }
}

const randomizeList = (list: string[]) => {
  const options: string[] = [...list]
  const randomList: string[] = Array(options.length)

  for (let i = 0; i < randomList.length; i++) {
    const [value] = options.splice(
      Math.floor(Math.random() * options.length),
      1,
    )
    randomList[i] = value
  }

  return randomList
}

const createQuestionPage = (config: QuestionPageConfig) => {
  const configObj: any = data[config.key]
  const listOptions: string[] = configObj.options
    ? configObj.randomize
      ? randomizeList(configObj.options)
      : configObj.options
    : []

  const component = (props: QuestionnairePageProps) => {
    const [value, setValue] = useState<number | string | boolean | null>(null)
    const { register } = useForm()

    const onChange =
      (subItem = false) =>
      (event: number | string | boolean | null) => {
        const returnValue =
          typeof event === 'number' && configObj.type === 'select'
            ? listOptions[event]
            : event

        if (!subItem) {
          // change comes from initial question
          setValue(returnValue)
          props.onChange?.(
            configObj.subItem &&
              configObj.subItem.shouldShowOnValue === returnValue
              ? [returnValue, null] // there will be a subItem visible -> null ensures questionnaire doesn't go to next page after onChange
              : returnValue, // there is no subItem -> set single value
          )
        } else {
          // change comes from subItem (second question)
          props.onChange?.([value, returnValue])
        }
      }

    // ensure values are null to disable next button (if present)
    useEffect(() => {
      props.onChange?.(null)
    }, [])

    return (
      <Question>
        {configObj.type === 'select' && (
          <SelectInput
            className="gap-x-[10px]"
            options={listOptions}
            onChange={onChange()}
          />
        )}
        {configObj.type === 'boolean' && <BooleanInput onChange={onChange()} />}
        {configObj.type === 'text' && (
          <TextFormField
            name="text-input"
            label={configObj.label}
            type="text"
            register={register}
            onChange={({ currentTarget: { value } }) =>
              onChange()(value ? value : null)
            }
          />
        )}
        {configObj.subItem?.shouldShowOnValue === value && (
          <div>
            {configObj.subItem.type === 'select' && (
              <SelectInput
                className="gap-x-[10px]"
                options={listOptions}
                onChange={onChange(true)}
              />
            )}
            {configObj.subItem.type === 'boolean' && (
              <BooleanInput onChange={onChange(true)} />
            )}
            {configObj.subItem.type === 'text' && (
              <TextFormField
                name="text-input"
                label={configObj.subItem.label}
                type="text"
                register={register}
                onChange={({ currentTarget: { value } }) =>
                  onChange(true)(value ? value : null)
                }
              />
            )}
          </div>
        )}
      </Question>
    )
  }

  const shouldShow = (values: QuestionnairePageValue[]) => {
    for (let i = 0; i < configObj.dependencies.length; i++) {
      const depend = configObj.dependencies[i]
      const dependValue = values
        .filter((v) => v.key === depend.key)
        .map((v) => v.value[0])?.[0]

      if (dependValue !== depend.value) return false
    }
    return true
  }

  const showButtons = (values: QuestionnairePageValue[]) => {
    const buttons: QuestionnaireButtonType[] = []
    const dependValue = values.find((v) => v.key === config.key)?.value

    if (config.order !== 0) buttons.push('back')
    if (
      !['select', 'boolean'].includes(configObj.type) ||
      (configObj.subItem &&
        !['select', 'boolean'].includes(configObj.subItem.type) &&
        configObj.subItem.shouldShowOnValue === dependValue?.[0])
    ) {
      buttons.push('next')
    }
    return buttons
  }

  return {
    buttons: [],
    showButtons,
    component,
    order: config.order,
    key: config.key,
    shouldShow,
    title: configObj.title,
  }
}

const createPages = () => {
  return Object.keys(data).map((key, index) =>
    createQuestionPage({
      order: index,
      key: key as HeardFromSurveyQuestionnairePageKey,
    }),
  )
}

const HeardFromSurvey = () => {
  const props = useViewModel()
  const breakpoint = useContext(BreakpointContext)
  const classes = breakpoint.smAndDown ? 'h-full' : 'h-[470px]'
  const pages = useMemo(() => createPages(), [])

  return (
    <Questionnaire
      id="heard-from-survey"
      className={classes}
      pages={pages}
      version="2.0"
      {...props}
    />
  )
}

export default HeardFromSurvey
